import { FC, useContext, useEffect } from 'react';
import classNames from 'classnames';
// components
import Section from 'src/components/Section';
import Typography from 'src/components/Typography';
// hooks
import useContactUsIntroData from 'src/cms/data/contactUs/useContactUsIntroData';
// contexts
import BannerContext from 'src/contexts/BannerContext';
import SitePreloaderContext from 'src/contexts/SitePreloaderContext';
// animations
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';

import * as style from './Intro.module.scss';

const Intro: FC = () => {
  // hooks
  const contactUsIntroData = useContactUsIntroData();
  // contexts
  const { isTopBannerVisible, topBannerRef } = useContext(BannerContext);
  const { isPageLoaded } = useContext(SitePreloaderContext);

  useEffect(() => {
    const animation = appearanceOfText(`.${style.animatedText}`).pause();

    if (isPageLoaded) {
      onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
        animation.play();
      });
    }
  }, [isPageLoaded]);

  return (
    <Section withMargin={false}>
      <div
        className={style.root}
        style={{
          marginTop: isTopBannerVisible
            ? topBannerRef.current?.clientHeight
            : 0,
        }}
      >
        <Typography
          variant='h1'
          className={classNames(style.title, style.animatedText)}
        >
          {contactUsIntroData.title}
        </Typography>
        <Typography
          variant='h3'
          className={classNames(style.subtitle, style.animatedText)}
        >
          {contactUsIntroData.subtitle}
        </Typography>
      </div>
    </Section>
  );
};

export default Intro;
