import { FC } from 'react';
// components
import PageLayout from 'src/components/PageLayout';
// sections
import Intro from './sections/Intro';
// hooks
import useContactUsMetaData from 'src/cms/data/contactUs/useContactUsMetaData';
import { PageProps } from 'gatsby';

const ContactUs: FC<PageProps> = ({ location }) => {
  // hooks
  const contactUsMetaData = useContactUsMetaData();

  return (
    <PageLayout metaData={{ ...contactUsMetaData, href: location.href }}>
      <Intro />
    </PageLayout>
  );
};

export default ContactUs;
